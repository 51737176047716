<template>
  <c-box flexGrow="1">
    <c-box
      v-if="isSucceeded"
      pos="fixed"
      top="200px"
      left="50%"
      transform="translateX(-50%)"
    >
      <c-alert
        status="info"
        bg="white"
        rounded="lg"
        box-shadow="0 0 20px rgba(0,0,0,.1)"
      >
        <c-image
          :src="require('@/assets/icon-check-active.svg')"
          alt="succeed icon"
        />
        <c-alert-description ml="20px">
          Password berhasil diubah
        </c-alert-description>
      </c-alert>
    </c-box>
    <c-flex flex-direction="column" :mx="[null, '20px']" :mt="[null, '25px']">
      <h1 v-chakra font-weight="bold" font-size="18px" color="#111" mb="10px">
        Ubah Password
      </h1>
      <hr />

      <c-form-control
        is-required
        :is-invalid="$v.oldPassword.$invalid"
        mt="40px"
        max-w="488px"
      >
        <c-form-label>Password Lama</c-form-label>
        <c-input-group>
          <c-input
            :type="shownPassword.includes('oldPassword') ? `text` : `password`"
            placeholder="Masukkan Password Lama"
            v-model="oldPassword"
            h="62px"
            max-w="488px"
            tabindex="1"
          />
          <c-input-right-element>
            <c-button
              variant="ghost"
              p="0"
              m="0"
              top="50%"
              right="10px"
              transform="translateY(-20%)"
              @click="togglePasswordVisibility('oldPassword')"
            >
              <c-image
                :src="require('@/assets/icon-show-password.svg')"
                alt="toggle password visibility"
              />
            </c-button>
          </c-input-right-element>
        </c-input-group>
      </c-form-control>
      <c-form-control
        is-required
        :is-invalid="$v.newPassword.$invalid"
        mt="40px"
        max-w="488px"
      >
        <c-form-label>Password Baru</c-form-label>
        <c-input-group>
          <c-input
            :type="shownPassword.includes('newPassword') ? `text` : `password`"
            placeholder="Masukkan Password Baru"
            v-model="newPassword"
            h="62px"
            max-w="488px"
            tabindex="1"
          />
          <c-input-right-element>
            <c-button
              variant="ghost"
              p="0"
              m="0"
              top="50%"
              right="10px"
              transform="translateY(-20%)"
              @click="togglePasswordVisibility('newPassword')"
            >
              <c-image
                :src="require('@/assets/icon-show-password.svg')"
                alt="toggle password visibility"
              />
            </c-button>
          </c-input-right-element>
        </c-input-group>
        <c-form-helper-text v-if="!$v.newPassword.minLength" color="red.500">
          Password minimal 8 karakter
        </c-form-helper-text>
        <c-form-helper-text v-if="!$v.newPassword.hasNumber" color="red.500">
          Password harus mengandung angka
        </c-form-helper-text>
        <c-form-helper-text
          v-if="!$v.newPassword.hasLowercaseLetter"
          color="red.500"
        >
          Password harus mengandung huruf kecil
        </c-form-helper-text>
        <c-form-helper-text
          v-if="!$v.newPassword.hasUppercaseLetter"
          color="red.500"
        >
          Password harus mengandung huruf kapital
        </c-form-helper-text>
        <c-form-helper-text
          v-if="!$v.newPassword.hasSpecialCharacter"
          color="red.500"
        >
          Password harus mengandung karakter spesial @$!%()*?&
        </c-form-helper-text>
      </c-form-control>

      <c-form-control
        is-required
        :is-invalid="$v.newPasswordConfirmation.$invalid"
        mt="40px"
        max-w="488px"
      >
        <c-form-label>Konfirmasi Password Baru</c-form-label>
        <c-input-group>
          <c-input
            :type="
              shownPassword.includes('newPasswordConfirmation')
                ? `text`
                : `password`
            "
            placeholder="Ulangi Password Baru"
            v-model="newPasswordConfirmation"
            h="62px"
            max-w="488px"
            tabindex="1"
          />
          <c-input-right-element>
            <c-button
              variant="ghost"
              p="0"
              m="0"
              top="50%"
              right="10px"
              transform="translateY(-20%)"
              @click="togglePasswordVisibility('newPasswordConfirmation')"
            >
              <c-image
                :src="require('@/assets/icon-show-password.svg')"
                alt="toggle password visibility"
              />
            </c-button>
          </c-input-right-element>
        </c-input-group>
        <!--Show error here-->
        <c-form-helper-text
          v-if="!$v.newPasswordConfirmation.sameAs"
          color="red.500"
        >
          Password baru tidak sama
        </c-form-helper-text>
      </c-form-control>
      <c-flex grid-gap="20px" max-w="488px">
        <c-button
          mt="40px"
          w="100%"
          h="62px"
          font-weight="bold"
          font-size="16px"
          rounded="full"
          @click="$router.push('/')"
        >
          Batal
        </c-button>
        <c-button
          mt="40px"
          :disabled="$v.$invalid"
          w="100%"
          h="62px"
          font-weight="bold"
          font-size="16px"
          variant-color="brand"
          rounded="full"
          @click="updatePassword"
        >
          Simpan
        </c-button>
      </c-flex>
    </c-flex>
  </c-box>
</template>

<script>
import { defineComponent, inject, ref } from "@vue/composition-api"
import { required, sameAs, minLength } from "vuelidate/lib/validators"
import { mapActions } from "vuex"

export default {
  name: "Settings",
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
      shownPassword: [],
      isSucceeded: false,
    }
  },
  methods: {
    ...mapActions({
      signout: "auth/signout",
    }),
    updatePassword() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      return this.axios
        .put(`/v1/users/update-password`, {
          currentPassword: this.oldPassword,
          newPassword: this.newPassword,
        })
        .then(() => {
          this.$v.$reset()
          this.isSucceeded = true
          this.oldPassword = ""
          this.newPassword = ""
          this.newPasswordConfirmation = ""

          setTimeout(() => {
            this.isSucceeded = false
            this.signout()
            this.$router.replace("/login")
          }, 3000)
        })
    },
    togglePasswordVisibility(name) {
      if (this.shownPassword.includes(name)) {
        this.shownPassword = this.shownPassword.filter((it) => it !== name)
      } else {
        this.shownPassword.push(name)
      }
    },
  },
  validations: {
    oldPassword: { required },
    newPassword: {
      required,
      minLength: minLength(8),
      hasNumber(value) {
        return /\d/.test(value)
      },
      hasLowercaseLetter(value) {
        return /[a-z]/.test(value)
      },
      hasUppercaseLetter(value) {
        return /[A-Z]/.test(value)
      },
      hasSpecialCharacter(value) {
        let reSpecialCharacter = /[@$!%()*?&]/
        // eslint-disable-next-line no-useless-escape
        return reSpecialCharacter.test(value)
      },
    },
    newPasswordConfirmation: { required, sameAs: sameAs("newPassword") },
  },
}
</script>
